@font-face {
  font-family: "Playlist Script";
  src: local("Playlist Script"),
    url(../../public/fonts/Playlist Script.otf) format("truetype");
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#comingsoon {
  position: relative;
  height: 50vh;
}

#comingsoon .comingsoon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.comingsoon {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.comingsoon .comingsoon-404 {
  position: relative;
  height: 240px;
}

.comingsoon .comingsoon-404 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -30px;
  margin-left: -20px;
}

.comingsoon .comingsoon-404 h1 > span {
  text-shadow: -7px 0px 0px #fff;
}

.comingsoon .comingsoon-404 h3 {
  font-family: "Montserrat", sans-serif;
  position: relative;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
  line-height: 5px;
}

.comingsoon h2 {
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .comingsoon .comingsoon-404 {
    height: 200px;
  }
  .comingsoon .comingsoon-404 h1 {
    font-size: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .comingsoon .comingsoon-404 {
    height: 162px;
  }
  .comingsoon .comingsoon-404 h1 {
    font-size: 152px;
    height: 140px;
    line-height: 162px;
  }
  .comingsoon h2 {
    font-size: 16px;
  }
}
